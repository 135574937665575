require('./bootstrap');

window.$ = window.jQuery = require('jquery');
require('jquery-mask-plugin');

$(document).ready(function(){
    $('#request_price').mask("#.##0,00", {reverse: true});
    $('#investment').mask("#.##0,00", {reverse: true});
    $('#edit_request_price').mask("#.##0,00", {reverse: true});
    $('#edit_investment').mask("#.##0,00", {reverse: true});
    $('#contact').mask('(00) 00000-0000');
}); 